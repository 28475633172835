@tailwind base;
@tailwind components;
@tailwind utilities;

/* .varela-round-regular {
  font-family: "Varela Round", sans-serif;
  font-weight: 400;
  font-style: normal;
}

.varela-round-bold {
  font-family: "Varela Round", sans-serif;
  font-weight: bold;
  font-style: normal;
} */

.no-scrollbar::-webkit-scrollbar {
  display: none;
}
html {
  overflow: hidden;
}

/* Hide scrollbar for IE, Edge, and Firefox */
.no-scrollbar {
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
}

.bg-gold-gradient {
  background: linear-gradient(135deg, #ffdd00 0%, #fbb034 100%);
}
.bg-primary-gradient {
  background: #763f98;
}

.truncate-multiline {
  display: -webkit-box;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
  -webkit-line-clamp: 2; /* Number of lines to show */
}
